import React from "react";
import { Helmet } from "react-helmet";

function Clutch() {
  return (
    <>
      <iframe src="/clutch.html" width="100%" />
    </>
  );
}

export default Clutch;
