import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/realizations.scss";
import Clutch from "../components/clutch";
import ContactUs from "../components/contactUs";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import SingleRealizationCard from "../components/singleRealizationCard";

function RealizationsPage({ pageContext }) {
  // eslint-disable-next-line
  const strapiHost = process.env.GATSBY_API_URL;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          realizations(locale: "${pageContext.intl.language}") {
           
            title
            slug
            logo {
              url
            }
            thumbnailOptionalLogo {
              url
            }
          }
          realizationPage(locale: "${pageContext.intl.language}"){
            pageTitle
            title
            description
            image{
              url
            }
          
            title2
            description2
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items);

      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout contactUs={false} nav={false}>
            <div>
              <div className="realizations__header">
                <img src={`${strapiHost}${data.realizationPage.image.url}`} />
                <div>
                  <h1 className="realizations__title">
                    {data.realizationPage.title}
                  </h1>
                  <p className="realizations__desc">
                    {data.realizationPage.description}
                  </p>
                </div>
              </div>
              <div className="realizations__wrapper">
                {data.realizations.map(
                  ({ title, logo, slug, thumbnailOptionalLogo }, key) => {
                    return (
                      <SingleRealizationCard
                        key={key}
                        title={title}
                        img={
                          thumbnailOptionalLogo
                            ? thumbnailOptionalLogo.url
                            : logo.url
                        }
                        to={`${slug}`}
                        bg="#f8f9fc"
                      />
                    );
                  }
                )}
              </div>
            </div>
          </Layout>
          <div className="realizations__section2">
            <Layout contactUs={false} nav={false} initial={false}>
              <div className="realizations__section2-wrapper">
                <div className="realizations__section2-left">
                  <Clutch />
                </div>
                <div className="realizations__section2-right">
                  <h2 className="realizations__title2">
                    {data.realizationPage.title2}
                  </h2>
                  <p className="realizations__description3">
                    {data.realizationPage.description2}
                  </p>
                </div>
              </div>
            </Layout>
          </div>
        </ShowProvider>
      )}
      <ContactUs />
    </>
  );
}

export default RealizationsPage;
